
.burgerWrapper {
    position: fixed;
    top: 8vmin;
    left: 2vmin;
    z-index: 9;
    display: grid;
    grid-template-columns: auto;
    cursor: pointer;
}
.burgerWrapper .burgerbtnClose {
    box-sizing: border-box;
    width: 6vmin;
    display: flex;
    padding: 0.5vmin 0.2vmin;
    flex-direction: column;
    row-gap: 1vmin;
    background-color: #6cc7b09d;
    border-radius: 4px;
}
.burgerWrapper .burgerbtnClose .line_blue {
    height: 1vmin;
    background-color: #1d29b0;
    border-radius: 20px;
}
/*       */
.burgerWrapper .container {
    background-color:rgba(0, 0, 0, 0.717);
    box-sizing: border-box;
    /* width: 228px; */
    padding: 2vmin 2vmin;
    display: grid;
    grid-template-columns: auto;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-family: 'lato';
}
.burgerWrapper .container .burgerbtnWhite {
    box-sizing: border-box;
    display: flex;
    padding: 0.5vmin 2.8vmin;
    padding-bottom: 1vmin;
    flex-direction: column;
    row-gap: 0.5vmin;
}
.burgerWrapper .container .burgerbtnWhite .line {
    height: 1vmin;
    background-color: #ffffff;
    border-radius: 15px;
}
/* i am here now */
.burgerWrapper .container .line_divider {
    height: 0.5vmin;
    background-color: #ffffff;
}
.burgerWrapper .container .menu_content ul {
    list-style-type: none;
    padding: 0px;
}
.burgerWrapper .container .menu_content ul .menuli {
    box-sizing: border-box;
    margin-top: 0.15vmin;
}

.burgerWrapper .container .menu_content ul .menuli a {
    font-size: 4vmin;
    color: white;
    text-decoration: none;
    font-weight: bold;
}

