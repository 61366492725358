.wrapper {
  width: 95vw;
  height: auto;
  margin: auto;
  background-color: rgba(11, 114, 170, 0.448);
  padding: 3vmin 2vw 10vmin 2vw;
  margin-top: 25vmin;
  border-radius: 20px;
  .first_screen {
    width: 100%;
    height: auto;
    display: block;
    font-family: "Lato";
    .title {
      font-family: "Baloo 2";
      //sfdsdfsdfs
      font-size: 7vmin;
      color: #ffffff;
      //background-color: rgb(252, 186, 3);
      width: 100%;
      padding-bottom: 3vmin;
      text-align: center;
    }
    .main {
      width: 100%;
      padding: 0 3vmin;
      font-size: 3.5vmin;
      color: rgb(255, 255, 255);
      margin: 1.5vmin 1vmin;
      p {
        margin-bottom: 3vmin;
      }
      ol {
        padding: 3vmin;
        padding-left: 5vmin;
      }
    }
    .musicTracksLayout {
      width: 100%;
      padding: 0 3vmin;
      font-size: 3vmin; 
      .musicTracks {
        display: flex;
        flex-direction: column;
        row-gap: 4vmin;
        margin-left: auto;
        margin-right: auto;
        color: #ffffff;
        width: 100%;
        .musicTrack {
          display: grid;
          grid-template-rows: 15vmin auto;
          gap: 2vmin;
          .trackTitle {
            font-size: 4vmin;
          }
          .trackPlayer {
            padding: 10vmin;
            width: 100%;
          }
        }
      }
    }
  }
  .teams {
    margin: 0 auto;
    margin-bottom: 15vmax;
    margin-top: 1vmin;
    width: 100%;
    font-size: 3.5vmin;
    text-align: center;
    font-family: "Baloo 2";
    color: white;
  }
  .teams p {
    font-family: "Lato";
  }
}

.logo {
  right: 0;
  left: 0;
  top: 0.3vmin;
  margin: 0 auto;
  position: absolute;
  background-image: url("../../resources/logo_calminder.png");
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  z-index: 11;
  width: 22vmax;
  height: 10vmax;
}
.amielogo {
  top: 0.5vmax;
  left: 13vw;
  position: absolute;
  background-image: url("../../resources/logo_amie.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 100 !important;
  width: 35vmin;
  height: 10vmax;
  overflow: hidden;
}
.erasmuslogo {
  top: 0.5vmax;
  right: 6vw;
  position: absolute;
  background-image: url("../../resources/logo_erasmus.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 110 !important;
  width: 35vmin;
  height: 8vmax;
  overflow: hidden;
}


