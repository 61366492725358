
.shadow {
    position: absolute;
    width: 100vw;
    height: auto; /*250vh*/
    background-color: rgba(0, 0, 0, 0.461);
    font-family: 'Baloo 2';
    z-index: 8;
}

.shadow .wrapper {
    margin: 0 auto;
    margin-top: 20vmin;
    width: 80%;
    height: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 3%;
    color: white;
    background-color: rgba(0, 0, 0, 0.306);
    border-radius: 20px;
}

.shadow .wrapper .goal {
    padding: 0 2vmin;
    overflow: auto;
}

.shadow .wrapper .goal .title {
    font-size: 6vmin;
    width: 80%;
    margin-bottom: 0.5vmin;
}
.shadow .wrapper .goal .pic {
   background-image: url('../../resources/line.png');
   background-repeat: no-repeat;
   background-position: left;
   background-size: 100%;
   height: 10vmin;
   width: 100%;
   margin-bottom: 1vmin;

}
.shadow .wrapper .goal .title2 {
    position: relative;
    background-image: url('../../resources/star.png');
    background-repeat: no-repeat;
    background-position: left;
    background-size: 5vmin;
    margin-bottom: 2vmin;
}
.shadow .wrapper .goal .title2 .text2 {
    font-size: 4vmin;
    margin-left: 7vmin;
    overflow: auto;
}
.shadow .wrapper .goal .value {
    font-size: 3.2vmin;
    list-style-type: none;
    font-family:'Lato';
    font-weight: 500;
    height: fit-content;
}

/* container for an image of exercise */
.shadow .wrapper .card {
    grid-area: 1 / 1 / span 1 / span 2;
    position: relative;
    width: 100%;
    height: 75vmin;
    padding: 2%;
}
.shadow .wrapper .video {
    display: none;
}
/* */
.shadow .wrapper .videoPlayer {
    grid-area: 1 / 1 / span 1 / span 2;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    height: 75vmin;
    padding: 2%;
    margin-top: 0%;
}

.shadow .wrapper .NoVideoPlayer {
    display: none;
}

/* */
.cardActiveWrapper {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.711);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    font-family: 'Baloo 2';
}

.cardCloseWrapper {
    display: none;
}

.cardActiveWrapper .cardActive {
    border-radius: 38px;
    position: relative;
    width: 100vw;
    height: 80vmin;
    padding: 2%;
    margin: auto;
    margin-top: 4vh;
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr;
    background-color:rgba(0, 0, 0, 0.605);
    color: rgb(255, 255, 255);
    z-index: 11;
}

.cardActiveWrapper .cardActive .title {
    text-align: left;
    font-size: 5.5vmin;
    padding: 1vmin;
}
.cardActiveWrapper .cardActive .bgImage {
    background-size: contain;
    background-repeat: no-repeat;
    background-clip: padding-box;
    background-position: center;
}
.cardActiveWrapper .cardActive .close{
    font-size: 5.5vmin;
    text-align: right;
}

.shadow .wrapper .info {
    padding: 0 2vmin;
    overflow: auto;
}

.shadow .wrapper .info .item:nth-child(n+2) {
    margin-top: 2vmin;

}
/* I am here now */
.shadow .wrapper .info .item .title {
    position: relative;
    background-image: url('../../resources/star.png');
    background-repeat: no-repeat;
    background-position: left;
    background-size: 5vmin;
}

.shadow .wrapper .info .item .title .text {
    /* here */
    font-size: 4vmin;
    margin-left: 7vmin;
}

.shadow .wrapper .info .item .desc {
    font-size: 3.2vmin;
    font-weight: 500;
    font-family:'Lato';
    list-style-type: none;
    overflow: auto;
}
.shadow .wrapper .info .item .desc .scrolltext{
    height: auto;
}

.shadow .logo {
    right: 0;
    left: 0;
    top: 0.3vmin;
    margin: 0 auto;
    position: absolute;
    background-image: url("../../resources/logo_calminder.png");
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    z-index: 11;
    width: 22vmax;
    height: 10vmax;
}
.shadow .amielogo {
    top: 0.5vmax;
    left: 18vmin;
    position: absolute;
    background-image: url("../../resources/logo_amie.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 100 !important;
    width: 18vmin;
    height: 8vmax;
    overflow: hidden;
}
.shadow .erasmuslogo {
    top: -2vmax;
    right: 5.5vmin;
    position: absolute;
    background-image: url("../../resources/logo_erasmus.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 110 !important;
    width: 35vmin;
    height: 8vmax;
    overflow: hidden;
}