.wrapper {
    width: 80vw;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.377);
    padding: 3vmin 2vmin 10vmin 2vmin;
    margin-top: 18vh;
    border-radius: 15px;
    .first_screen {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        p {
            margin-bottom: 4vmin;
        }
        .full {
            font-size: 3.5vmin;
            font-family:'Lato';
            text-align: center;
            color: white;
        }
        .title {
            font-family: 'Baloo 2';
            font-size: 7vmin;
            text-align: center;
            color: #ebebf5;
            width: 100%;
            padding-bottom: 5vmin;
        }
        .left, .right {
            width: 50%;
            font-size: 3.5vmin;
            font-family:'Lato';
            color: white;
            img {
                width: 100%;
            }
            .leaves {
                img {
                    position: relative;
                    left: 50%;
                    width: 40%;
                }
            }
        }
    }
    .second_screen {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        p {
            margin-bottom: 4vmin;
        }
        .title {
            font-family: 'Baloo 2';
            font-size: 7vmin;
            color: #ebebf5;
            width: 100%;
            text-align: center;
            padding-bottom: 5vmin;
        }

        .left, .right {
            width: 50%;
            font-size: 3.5vmin;
            font-family: 'Lato';
            color: white;

            img {
                width: 60%;
            }
        }
    }
    .third_screen {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        p {
            margin-bottom: 4vmin;
        }
        .title {
            font-family: 'Baloo 2';
            font-size: 5.5vmin;
            color: #ebebf5;
            width: 100%;
            text-align: center;
            padding-left: 17vmin;
            padding-right: 17vmin;
            padding-bottom: 5vmin;
            margin-top: 4vmin;
        }
        .organisation {
            display: inline-grid;
            grid-template-columns: 25% auto;
            column-gap: 3%;
            row-gap: 0.5vmin;
            font-size: 3vmin;
            .educators {
                grid-column: 1 / span 2;
                font-family: 'Lato';
                color: white;
                text-align: center;
                h1 {
                    font-size: 5vmin;
                    text-decoration: underline;
                }
                h2 {
                    font-size: 3.5vmin;
                    margin-top: 0.35vmin;
                }
                p {
                    padding-top: 1.75vmin;
                }
                .separator {
                    width: 100%;
                    height: 2px;
                    background-color: #ffffff;
                    margin-top: 3vmin;
                    margin-bottom: 3vmin;
                }
            }
            .left, .right {
                font-family: 'Lato';
                color: white;
                p {
                    padding-top: 1.5vmin;
                }
                
                img {
                    width: 100%;
                } 
            }
        }
    }
    .fourth_screen {
        margin-top: 17vmin;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        p {
            margin-bottom: 4vmin;
        }
        .title {
            font-family: 'Baloo 2';
            font-size: 7vmin;
            color: #ebebf5;
            width: 100%;
            text-align: center;
            padding-bottom: 5vmin;
        }
        .left, .right, .center {
            width: 33.3%;
            font-size: 3.5vmin;
            font-family: 'Lato';
            color: white;
            p {
                width: 100%;
            }
            img {
                width: 57%;
            }
        }
    }
    .teams {
        margin: 0 auto;
        text-align: center;
        font-family: 'Baloo 2';
        color:white;
        margin-bottom: 15vmax;
        margin-top: 15vmin;
        width: 70%;
        font-size: 3.5vmin;
    }
    .teams p{
        
        font-family: 'Lato';
        
    }
    .teams h1{
        font-size: 5vmin;
        text-decoration: underline;
    }

}

    .logo {
        right: 0;
        left: 0;
        top: 0.3vmin;
        margin: 0 auto;
        position: absolute;
        background-image: url("../../resources/logo_calminder.png");
        background-position: center;
        background-size: 50%;
        background-repeat: no-repeat;
        z-index: 11;
        width: 22vmax;
        height: 10vmax;
    }
    .amielogo {
        top: 0.5vmax;
        left: 13vw;
        position: absolute;
        background-image: url("../../resources/logo_amie.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 100 !important;
        width: 35vmin;
        height: 10vmax;
        overflow: hidden;
    }
    .erasmuslogo {
        top: 0.5vmax;
        right: 6vw;
        position: absolute;
        background-image: url("../../resources/logo_erasmus.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 110 !important;
        width: 35vmin;
        height: 8vmax;
        overflow: hidden;
    }

    